const getHostedCheckoutReturnValue = (url) => {
    var _a;
    const sessionId = (_a = url.pathname.match(/\/pay\/([^?/#]+)/)) === null || _a === void 0 ? void 0 : _a[1];
    return sessionId ? { origin: 'Hosted Checkout', sessionId } : undefined;
};
export const getSumupProduct = (url) => {
    try {
        const productURL = new URL(url);
        if (process.env.APP_ENV !== 'production' &&
            productURL.host.startsWith('op-hosted-checkout')) {
            return getHostedCheckoutReturnValue(productURL);
        }
        const envURL = new URL(process.env.HOSTED_CHECKOUT_ORIGIN);
        if (productURL.host === envURL.host) {
            return getHostedCheckoutReturnValue(productURL);
        }
        return undefined;
    }
    catch (_a) {
        return undefined;
    }
};
