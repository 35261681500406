function isValhallaCheckoutResponse(res) {
    return 'current_payment' in res;
}
export function convertResponseToOldFormat(res) {
    var _a, _b, _c, _d;
    if (isValhallaCheckoutResponse(res)) {
        const oldResponse = {
            id: res.id,
            description: res.description,
            merchant_name: res.merchant.name,
            merchant_code: res.merchant.code,
            status: res.status,
            amount: res.amount,
            currency: res.currency,
            checkout_reference: res.reference,
            transaction_code: (_a = res.current_payment) === null || _a === void 0 ? void 0 : _a.transaction_code,
            transaction_id: (_b = res.current_payment) === null || _b === void 0 ? void 0 : _b.id,
        };
        if (res.status === 'PAID') {
            oldResponse.paid_at = (_c = res.current_payment) === null || _c === void 0 ? void 0 : _c.updated_at;
        }
        if ((_d = res.current_payment) === null || _d === void 0 ? void 0 : _d.next_action) {
            const { url, method, payload = [], mechanism = ['iframe'], pre_action: preAction, } = res.current_payment.next_action.redirect;
            oldResponse.next_step = {
                mechanism,
                url,
                method,
                payload: payload.reduce((payload, { name, value }) => (Object.assign(Object.assign({}, payload), { [name]: value })), {}),
                pre_action: preAction,
            };
        }
        return oldResponse;
    }
    return res;
}
