import name from './card/name';
import number from './card/number';
import expiration from './card/expiration';
import cvv from './card/cvv';

import { CARD, ON_LOAD_INDICATOR } from '../../constants/input-names';

export default {
  [CARD.NAME]: name,
  [CARD.NUMBER]: number,
  [CARD.EXPIRATION]: expiration,
  [CARD.CVV]: cvv,
  [ON_LOAD_INDICATOR]: { isPlaceHolder: true },
};
