/**
 * Messenger is responsible to hold all the information
 * required for filtering and formating messages between the host page
 * and the widget. For instance, 'variant' is variable that is generated
 * for each session of the widget and is verified on both sides of this
 * communication.
 *
 * It also holds the listeners that are shared among several components.
 *
 * This abstraction also encapsulate some of the security checks that are
 * described here:
 * https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/11-Testing_Web_Messaging.html
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const MESSENGER_UNMOUNT = 'messenger--unmount';
export const WidgetEvents = Object.freeze({
    TYPE: 'SumUpCard',
    ACTION_MESSAGE: 'message',
});
const isBroadcastMessage = (data) => data.type === WidgetEvents.TYPE;
const isTrustedMessage = (data, variant, options) => {
    return Boolean(isBroadcastMessage(data) &&
        data.action === WidgetEvents.ACTION_MESSAGE &&
        (data.variant === variant || options.ignoreVariant) &&
        data.message);
};
const messenger = ({ id, fromWindow, toWindow, variant, origin, autoMount = false, onError = ({ error }) => {
    throw error;
}, }) => {
    const listeners = {};
    const broadcastListeners = {};
    const msgHandler = (parentMessageEvent) => {
        const { data, source } = parentMessageEvent;
        if (!data || !data.message) {
            return;
        }
        if (isBroadcastMessage(data)) {
            (broadcastListeners[data.message] || []).forEach((bcl) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield Promise.resolve(bcl.handler(data.value, data));
                }
                catch (e) {
                    // eslint-disable-next-line
                    console.error(`Error while receiving message: ${id} - ${data.message}`, e);
                    onError({
                        error: e,
                        message: `Error while receiving message: ${id} - ${data.message}`,
                    });
                }
            }));
        }
        if (toWindow && source !== toWindow) {
            return;
        }
        (listeners[data.message] || []).forEach((l) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (isTrustedMessage(data, variant, l.options || {})) {
                    yield Promise.resolve(l.handler(data.value, data));
                }
            }
            catch (e) {
                // eslint-disable-next-line
                console.error(`Error while receiving message: ${id} - ${data.message}`, e);
                onError({
                    error: e,
                    message: `Error while receiving message: ${id} - ${data.message}`,
                });
            }
        }));
    };
    const msn = {
        unsubscribe: (eventName, handler) => {
            if (!listeners) {
                return;
            }
            listeners[eventName] = (listeners[eventName] || []).filter(({ handler: h }) => h !== handler);
        },
        onAny: (eventName, handler) => {
            if (!broadcastListeners) {
                return;
            }
            broadcastListeners[eventName] = [
                { handler, options: { ignoreVariant: true } },
            ];
        },
        on: (eventName, handler, options = {}) => {
            if (!listeners) {
                return;
            }
            if (options.uniqueListener) {
                listeners[eventName] = [{ handler, options }];
                return;
            }
            listeners[eventName] = [
                ...(listeners[eventName] || []),
                { handler, options },
            ];
        },
        sendTo: (targetWindow, message) => {
            const msg = Object.assign({ variant, type: WidgetEvents.TYPE }, message);
            targetWindow.postMessage(msg, '*');
        },
        send: (message) => {
            try {
                const msg = Object.assign({ variant, type: WidgetEvents.TYPE, action: WidgetEvents.ACTION_MESSAGE }, message);
                if (toWindow) {
                    toWindow.postMessage(msg, origin || '*');
                    return;
                }
                if (fromWindow) {
                    // FIXME identify a way to get the parent domain instead of using
                    // the wildcard match '*'
                    fromWindow.parent.postMessage(msg, '*');
                }
            }
            catch (e) {
                // eslint-disable-next-line
                console.error(`Error while sending message: ${id}`, e);
                onError({
                    error: e,
                    message: `Error while sending message: ${id}`,
                });
            }
        },
        mount: () => {
            fromWindow === null || fromWindow === void 0 ? void 0 : fromWindow.addEventListener('message', msgHandler);
        },
        unmount: () => {
            // unmount the "listener" if it is mounted on the "toWindow" window.
            msn.send({ message: MESSENGER_UNMOUNT });
            fromWindow === null || fromWindow === void 0 ? void 0 : fromWindow.removeEventListener('message', msgHandler);
            // Free window instances which can be garbage collected so we avoid
            // memory leaks.
            // https://web.dev/detached-window-memory-leaks/
            fromWindow = undefined;
            toWindow = undefined;
            Object.keys(listeners).forEach((key) => delete listeners[key]);
            Object.keys(broadcastListeners).forEach((key) => delete broadcastListeners[key]);
        },
    };
    if (autoMount) {
        msn.mount();
    }
    msn.on(MESSENGER_UNMOUNT, () => {
        msn.unmount();
    });
    return msn;
};
export default messenger;
