const handleObject = (cleanObject, key, value) => {
  const properties = Object.keys(value);
  if (!properties.length) {
    return cleanObject;
  }

  return { ...cleanObject, [key]: value };
};

export const removeEmptyProperties = (objectWithEmptyProps) => {
  return Object.keys(objectWithEmptyProps).reduce((cleanObject, key) => {
    const value = objectWithEmptyProps[key];
    if (
      (typeof value !== 'number' && !value) ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      return cleanObject;
    }

    if (value.filter) {
      return {
        ...cleanObject,
        [key]: value.filter((isPresent) => isPresent),
      };
    }

    if (typeof value === 'object') {
      return handleObject(cleanObject, key, removeEmptyProperties(value));
    }

    return { ...cleanObject, [key]: value };
  }, {});
};
