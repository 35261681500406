import Pythia, { getBrowserTags } from '@sumup/pythia';
import environment, { DEV } from './environment';

const pythia = new Pythia({
  apiToken: '6C5CB6DE-F4FE-40D5-B8E9-C364190EEE32',
  defaultTags: { appName: 'ecom-card-widget', ...getBrowserTags() },
});

const env = environment(window.location.href);

export function sendMetric(metric) {
  if (env === DEV) {
    // eslint-disable-next-line
    return console.log('pythia-event:', JSON.stringify(metric));
  }

  return pythia.send({
    counterMetrics: [metric],
  });
}

export const integrationOrigin = (hostname) => {
  if (!hostname) {
    return '';
  }

  const internalDomains = ['sumup', 'debitoor'];
  return internalDomains.find((d) => hostname.includes(d))
    ? hostname.split('.').join('_')
    : 'external';
};
