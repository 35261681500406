// prettier-ignore
export const EXPIRY_DATE_MASK = [
    /(0|1)/, /\d/, '/',
    /\d/, /\d/, /\d/, /\d/,
];
// prettier-ignore
export const CARD_NUMBER_MASK = [
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/
];
export const CPF_NUMBER_MASK = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    // This extra digit allows the change between CPF/CNPJ
    // not really part of CPF format 000.000.000-00
    /\d/,
];
export const CNPJ_NUMBER_MASK = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];
export const DATE_FORMAT_MASK = [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
