export const PROD = 'production';
export const THETA = 'theta';
export const STAGE = 'stage';
export const DEV = 'development';

export default (url) => {
  // if this is true whatever comes after this block of
  // code is going to be removed from the bundle
  if (process.env.APP_ENV === PROD) {
    return PROD;
  }

  if (url.includes('theta')) {
    return THETA;
  }

  if (url.includes('sam-app')) {
    return STAGE;
  }

  return DEV;
};
