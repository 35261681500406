/**
 * This module contains formats that will be included on the API. Using
 * these the widget can start to slowly migrate to the new format and once it
 * is available on the API they can be removed.
 *
 * This is inspired by Python's "from __future__ import module"
 */

// Pending Payment Artifacts
//
// This will format the response to the RFC-109 format while the API
// is still responding with the old format
//
// see: https://sumupteam.atlassian.net/l/c/QU2n75xS
export const pendingPaymentArtefacts = (paymentResp) => {
  if ('pix' in paymentResp) {
    const { pix, ...rest } = paymentResp;
    const body = {
      ...rest,
      payment_method: 'pix',
      pix,
      artefacts: [
        {
          name: 'instructions',
          content_type: 'text',
          content: 'instructions',
          created_at: 'not used',
        },
        ...(pix.artefacts || []),
      ],
    };

    return body;
  }

  if ('qr_code_pix' in paymentResp) {
    const { qr_code_pix: qrCodePix, ...rest } = paymentResp;
    const body = {
      ...rest,
      payment_method: 'qr_code_pix',
      qr_code_pix: qrCodePix,
      artefacts: [
        {
          name: 'instructions',
          content_type: 'text',
          content: 'instructions',
          created_at: 'not used',
        },
        ...(qrCodePix.artefacts || []),
      ],
    };

    return body;
  }

  if ('boleto' in paymentResp) {
    const { boleto, ...rest } = paymentResp;

    return {
      ...rest,
      payment_method: 'boleto',
      boleto,
      artefacts: [
        {
          name: 'invoice',
          content_type: 'application/pdf',
          content: boleto.url,
          created_at: 'not used',
        },
        {
          name: 'code',
          content_type: 'text/plain',
          content: boleto.barcode,
          created_at: 'not used',
        },
        {
          name: 'disclaimer',
          content_type: 'text/plain',
          content: 'disclaimer',
          created_at: 'not used',
        },
        ...(boleto.artefacts || []),
      ],
    };
  }

  return paymentResp;
};
