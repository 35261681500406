import messenger from 'messenger';
import * as logger from '../../modules/logger';
import { sendMetric } from '../../utils/metrics';
import { MESSAGE_METRICS_TRACK } from '../constants/aux-events';

export function init(formWin) {
  const msn = messenger({
    id: formWin.name,
    fromWindow: formWin,
    variant: 'hostedfield',
    autoMount: true,
    onError: ({ error, message }) => {
      logger.tag('messenger_id', formWin.name);
      logger.error(error, message);
    },
  });

  return {
    onLoad: () => {
      msn.onAny(MESSAGE_METRICS_TRACK, ({ metric, labels }) => {
        sendMetric({
          name: metric,
          labels,
        });

        msn.send({ message: `${MESSAGE_METRICS_TRACK}--ack` });
      });
    },
    onUnload: () => {
      msn.unmount();
    },
  };
}
