export const ERRORS = {
  none: null,
  required: 'required',
  invalid: 'invalid',
};

// Just syntax sugar for readability
export const isInvalid = false;
export const isValid = true;

export default (...validations) =>
  (...args) =>
    validations.reduce((error, validate) => error || validate(...args), '');
