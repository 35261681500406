import validations, { ERRORS, isInvalid, isValid } from '../validations';
import mask from '../../../../modules/mask';
import { EXPIRY_DATE_MASK } from '../../../../components/Form/modules/masks';

const isValidExpiryDate = (value) => {
  const [mm, yyyy] = value.split('/');
  if (!mm || !yyyy) {
    return isInvalid;
  }

  if (mm.length > 2 || yyyy.length > 4) {
    return isInvalid;
  }

  const expireMonth = Number(mm);
  if (expireMonth === 0 || expireMonth > 12) {
    return isInvalid;
  }

  const currDate = new Date();
  const currYear = currDate.getFullYear();
  const currMonth = currDate.getMonth();

  const fullYear = `${String(currYear).slice(0, 2)}${yyyy}`.slice(-4);
  const expireYear = Number(fullYear);

  const isExpired =
    currYear > expireYear ||
    (currYear === expireYear && currMonth + 1 > expireMonth);

  return isExpired ? isInvalid : isValid;
};

const handlers = {
  maxLength: 8,
  mask: (v) => mask(v, EXPIRY_DATE_MASK),
  validate: validations(
    (value) => (value ? ERRORS.none : ERRORS.required),
    (value) => (isValidExpiryDate(value) ? ERRORS.none : ERRORS.invalid),
  ),
};

export default handlers;
