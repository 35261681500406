import validations, { ERRORS } from '../validations';
import mask from '../../../../modules/mask';

/** @type {(value: string, input: HTMLInputElement) => boolean} */
const isValidCvv = (value, input) => {
  if (input.maxLength > 0) {
    const digits = value.replace(/\D+/g, '');

    if (value !== digits) {
      return false;
    }

    return digits.length === input.maxLength;
  }
  return /^\d{3,4}$/.test(value);
};

const handlers = {
  mask: (v) => mask(v, [/\d/, /\d/, /\d/, /\d?/]),
  validate: validations(
    (value) => (value ? ERRORS.none : ERRORS.required),
    (value, input) => (isValidCvv(value, input) ? ERRORS.none : ERRORS.invalid),
  ),
};

export default handlers;
