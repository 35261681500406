import { CARD } from '../constants/input-names';
const removeSpaces = (value) => (value || '').replace(/\s/g, '');
const INPUT_TO_PAYLOAD = Object.freeze({
    [CARD.NAME]: (input) => ({
        name: (input.value || '').trim(),
    }),
    [CARD.NUMBER]: (input) => ({ number: removeSpaces(input.value) }),
    [CARD.EXPIRATION]: (input) => {
        const [month, year] = removeSpaces(input.value).split(/\//);
        return {
            expiry_month: month,
            expiry_year: year,
        };
    },
    [CARD.CVV]: (input) => ({
        cvv: removeSpaces(input.value),
    }),
});
const formatInputsToPayload = (inputs) => {
    return inputs.reduce((payload, input) => {
        if (!INPUT_TO_PAYLOAD[input.name]) {
            return payload;
        }
        return Object.assign(Object.assign({}, payload), INPUT_TO_PAYLOAD[input.name](input));
    }, {});
};
export default formatInputsToPayload;
