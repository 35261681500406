import validations, { ERRORS } from '../validations';
import mask from '../../../../modules/mask';
import { getCardType, luhnCheckFast } from '../../../../utils/validations';

import { CARD_NUMBER_MASK } from '../../../../components/Form/modules/masks';

const hasValidLuhnNumber = (value) => {
  const scheme = getCardType(value);
  if (!scheme || !scheme.validLength) {
    return false;
  }

  const valueWithoutSpaces = (value || '').replace(/\s/g, '');
  return luhnCheckFast(valueWithoutSpaces);
};

export default {
  maxLength: 23,
  mask: (value) => {
    return mask(value, CARD_NUMBER_MASK);
  },
  validate: validations(
    (value) => (value ? ERRORS.none : ERRORS.required),
    (value) => (hasValidLuhnNumber(value) ? ERRORS.none : ERRORS.invalid),
  ),
  recognize: (value) => {
    const scheme = getCardType(value);

    if (!scheme) {
      return null;
    }

    return scheme;
  },
};
