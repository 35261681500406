var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ClientErrorFormat from './errors';
export default (response) => __awaiter(void 0, void 0, void 0, function* () {
    const contentType = response.headers.get('content-type');
    const jsonResponse = yield response.json().catch((e) => {
        throw ClientErrorFormat({
            status: response.status,
            data: {
                status: response.status,
                type: 'https://developer.sumup.com/rest-api/problems/invalid-format',
                title: 'Unexpected content-type',
                detail: `The client received an unexpected content-type. ` +
                    ` Content-type: ${contentType}` +
                    ` Error ${e.message} ${response.status}`,
            },
        });
    });
    if (response.status >= 400) {
        if (!jsonResponse) {
            throw ClientErrorFormat({
                status: response.status,
                data: {
                    status: response.status,
                    type: 'https://developer.sumup.com/docs/problem/internal-server-error/',
                    title: 'Error with empty content',
                    detail: `The client received an error without content body. ` +
                        ` Content-type: ${contentType}` +
                        ` Error response body is empty. ${response.status}`,
                },
            });
        }
        throw ClientErrorFormat({
            status: response.status,
            data: jsonResponse,
        });
    }
    return jsonResponse;
});
