export class InvalidDataError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidDataError';
    this.code = 'INVALID_DATA';
  }
}

export const rejectInvalidCheckout = (checkoutId) =>
  Promise.reject(new InvalidDataError(`Invalid UUID checkout: ${checkoutId}`));
