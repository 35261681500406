import { removeEmptyProperties } from '../../utils/object';

// All card payments are forwarded to https://github.com/sumup/checkouts
// therefore they have a legacy format
const isLegacyApi = (payload) => payload.payment_type === 'card';

export default (payload) => {
  const formattedPayload = { ...payload };

  if (!isLegacyApi(payload)) {
    return removeEmptyProperties(payload);
  }

  if (payload.personal_details && payload.personal_details.tax_id) {
    formattedPayload.card = {
      ...payload.card,
      cpf_number: payload.personal_details.tax_id,
    };

    formattedPayload.personal_details.tax_id = null;
  }

  return removeEmptyProperties(formattedPayload);
};
