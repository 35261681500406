// We are handling with API data that has a snake case format.
/* eslint camelcase: 0 */
export const format3DSDataRedirectIframe = (res) => {
    if (res.next_step) {
        const { next_step } = res;
        const hasIframeRedirect = (next_step.mechanism || []).includes('iframe');
        const hasBrowserRedirect = (next_step.mechanism || []).includes('browser');
        // To see how this data is transfered
        // See ./src/redirect.html
        return {
            iframe_redirect: hasIframeRedirect && !hasBrowserRedirect,
            next_step,
        };
    }
    // the response is not related to APM redirect or 3DS challenge
    return res;
};
