import messenger from 'messenger';
import fields from './fields';
import {
  MESSAGE_INPUT_RECOGNIZE,
  MESSAGE_INPUT_CHANGE,
  MESSAGE_INPUT_FOCUS,
  MESSAGE_INPUT_BLUR,
  MESSAGE_INPUT_INITIALIZE,
} from '../constants/field-events';
import { MESSAGE_FORM_INVALID_INPUT } from '../constants/form-events';

export function init(input) {
  input.disabled = true;

  const msn = messenger({
    fromWindow: window,
    variant: 'hostedfield',
    autoMount: true,
  });

  const usp = new URLSearchParams(window.location.search);
  window.name = usp.get('name') || window.name;
  const { name } = window;

  const field = fields[name];
  if (!field) {
    throw new Error(`Invalid hosted field type (name: ${name})`);
  }

  if (field.isPlaceHolder) {
    input.style.display = 'none';
  }

  msn.on(MESSAGE_INPUT_INITIALIZE, (options) => {
    input.setAttribute('placeholder', options.placeholder);
    input.setAttribute('type', options.type);
    input.setAttribute('autocomplete', options.autocomplete);
    input.setAttribute('data-isvalid', options.valid ? 'true' : 'false');
    if (options.maxLength && typeof options.maxLength === 'number') {
      input.setAttribute('maxLength', options.maxLength);
    }
    input.disabled = false;
  });

  msn.onAny(MESSAGE_FORM_INVALID_INPUT, () => {
    msn.send({ message: MESSAGE_INPUT_BLUR, value: { name } });
  });

  return {
    mask: (value) => {
      if (!field.mask) {
        return value;
      }

      return field.mask(value);
    },
    validate: (value, element) => {
      if (!field.validate) {
        return;
      }

      const error = field.validate(value, element);
      input.setAttribute('data-isvalid', error ? 'false' : 'true');
      msn.send({
        message: MESSAGE_INPUT_CHANGE,
        value: { name, error, shouldShowError: true },
      });
    },
    recognize: (value) => {
      if (!field.recognize) {
        return;
      }

      const messageValue = field.recognize(value);
      if (messageValue) {
        msn.send({
          message: MESSAGE_INPUT_RECOGNIZE,
          value: { value: messageValue, name },
        });
      }
    },
    onFocus: () => {
      msn.send({ message: MESSAGE_INPUT_FOCUS, value: { name } });
    },
    onBlur: () => {
      msn.send({ message: MESSAGE_INPUT_BLUR, value: { name } });
    },
    onUnload: () => {
      msn.unmount();
    },
  };
}
