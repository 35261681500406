import * as metadata from '../../payment-methods';

// Metadata is some extra information related to each payment method
// which determine things like the required fields as well as other
// configurations. See "../../payment-methods/{payment}.json" for more.
export default function enhanceApiResponseWithMetadata(payments) {
  const availablePaymentMethods = payments
    .map((p) => ({
      ...p,
      metadata: metadata[p.id],
    }))
    .filter((p) => p.metadata);

  if (
    process.env.APP_ENV !== 'production' &&
    availablePaymentMethods.length !== payments.length
  ) {
    // eslint-disable-next-line
    console.warn('Missing APM metadata for at least one payment method.');
  }

  return availablePaymentMethods;
}
