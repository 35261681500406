export const FORM = 'hosted-field-form';
export var CARD;
(function (CARD) {
    CARD["NAME"] = "cardDetails.cardholder";
    CARD["NUMBER"] = "cardDetails.number";
    CARD["EXPIRATION"] = "cardDetails.expiryDate";
    CARD["CVV"] = "cardDetails.securityCode";
})(CARD || (CARD = {}));
// This is a placeholder to trigger the onLoad
// together with the hosted fields it indicates
// if the form is fully loaded.
export const ON_LOAD_INDICATOR = 'on-load-indicator';
