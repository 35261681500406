const ERROR_CODE_MAP = {
  400: 'INVALID',
  401: 'NOT_AUTHORIZED',
  404: 'NOT_FOUND',
  409: 'CHECKOUT_PROCESSED',
};

const deprecation = ({ legacy, alternative }) =>
  // eslint-disable-next-line no-console
  console.warn(
    `SumupCard: The property '${legacy}' from onResponse error object is` +
      ` being deprecated. Please use '${alternative}' instead.`,
  );

const API_ERROR_NAME = 'APIError';

class APIError extends Error {
  constructor(message, data) {
    super(message);

    this.name = API_ERROR_NAME;
    this.data = data;
  }
}

// Parameters:
//
// @status is the response status code
// @data is the response body from the API as json
//
export default ({ status, data }) => {
  return new APIError(data.message || `${data.title}: ${data.detail}`, {
    ...data,
    // This avoids causing errors to who uses the legacy properties
    // and also warns about the deprecation of them.
    //
    // These warning will only show on accessing these legacy properties.
    get error_code() {
      deprecation({ legacy: 'error_code', alternative: 'type' });

      return ERROR_CODE_MAP[status];
    },
    get message() {
      deprecation({ legacy: 'message', alternative: 'detail' });

      return `${data.title}: ${data.detail}`;
    },
    // This property is for showing validation errors in the widget
    param:
      data.param || // in case of legacy response
      (data.failed_constraints &&
        data.failed_constraints[0] &&
        data.failed_constraints[0].reference),
    status,
  });
};
