import messenger from 'messenger';
import { initApiClient } from 'api';
import * as logger from '../../modules/logger';

import {
  MESSAGE_FORM_SUBMIT,
  MESSAGE_FORM_SUBMIT_SENT,
  MESSAGE_FORM_ON_RESULT,
  MESSAGE_FORM_ON_ERROR,
  MESSAGE_FORM_INVALID,
  MESSAGE_FORM_INVALID_INPUT,
} from '../constants/form-events';

import { CARD } from '../constants/input-names';
import formatInputsToPayload from '../modules/input-to-payload';
import { removeEmptyProperties } from '../../utils/object';
import { parseRedirectMechanism } from '../../utils/sca-experience-mode';

const has = (value) => !!value;

const hostedFieldsFromFrames = (frames) => {
  return Object.keys(CARD)
    .map((input) => {
      try {
        return frames[CARD[input]];
      } catch (e) {
        // eslint-disable-next-line
        console.warn('WARNING: blocked access to frame for: ', CARD[input]);
        return null;
      }
    })
    .filter((frame) => has(frame))
    .map((hfFrame) => {
      const input = hfFrame.document.querySelector('input');
      return {
        frame: hfFrame,
        input,
        isValid: input && input.dataset.isvalid === 'true',
      };
    })
    .filter((hf) => has(hf.input));
};

export function init(formWin, hostWin) {
  const msn = messenger({
    id: formWin.name,
    fromWindow: formWin,
    toWindow: formWin.parent,
    variant: 'hostedfield',
    origin: document.referrer,
    autoMount: true,
    onError: ({ error, message }) => {
      logger.tag('messenger_id', formWin.name);
      logger.error(error, message);
    },
  });

  msn.onAny(MESSAGE_FORM_SUBMIT, ({ payment, sessionId, product }) => {
    const api = initApiClient(process.env.API_URL, sessionId, product);

    let cardInputs = {};
    let hfields = [];
    if (payment.payment_type === 'card') {
      hfields = hostedFieldsFromFrames(hostWin.frames);

      if (!hfields.length) {
        return;
      }

      const invalidFields = hfields.filter((hf) => !hf.isValid);

      if (invalidFields.length) {
        invalidFields.forEach(({ frame, input }) => {
          msn.sendTo(frame, {
            message: MESSAGE_FORM_INVALID_INPUT,
            value: { name: input.name },
          });
        });

        msn.send({
          message: MESSAGE_FORM_INVALID,
          value: {
            message: 'Invalid form submit.',
          },
        });
        return;
      }

      cardInputs = formatInputsToPayload(
        hfields.filter(({ input }) => !!input).map(({ input }) => input),
      );
    }

    const payload = removeEmptyProperties({
      payment_type: payment.payment_type || 'card',
      device_information: payment.device_information,
      method_details: payment.method_details,
      personal_details: payment.personal_details,
      card: { ...payment.card, ...cardInputs },
      installments: payment.installments || 1,
      fee_calculation_id: payment.fee_calculation_id,
      mandate: payment.mandate,
      direct_debit: payment.direct_debit,
      lxn_session_token: payment.lxn_session_token,
    });

    msn.send({ message: MESSAGE_FORM_SUBMIT_SENT });

    hfields.forEach(({ input }) => {
      input.disabled = true;
    });

    api.checkouts
      .processCheckout({
        checkoutId: payment.checkoutId,
        payload,
        redirectMechanism: parseRedirectMechanism(payment.scaExperienceMode),
      })
      .then((res) => {
        msn.send({ message: MESSAGE_FORM_ON_RESULT, value: res });
      })
      .catch((error) => {
        if (error.name === 'APIError') {
          const { data, message } = error;
          if (data.status === 400) {
            msn.send({
              message: MESSAGE_FORM_INVALID,
              value: {
                message,
                field: error.data.param,
                data,
              },
            });
          } else {
            msn.send({
              message: MESSAGE_FORM_ON_ERROR,
              value: {
                message,
                status: data.status,
                data,
              },
            });
          }

          return;
        }

        if (!(error instanceof Error)) {
          let asString;
          try {
            asString = JSON.stringify(error);
            logger.warning(`Non error handled ${error.name} ${asString}`);
          } catch (_) {
            logger.warning(`Non error handled not parseable ${error.name}`);
          }
        }
      })
      .finally(() => {
        hfields.forEach(({ input }) => {
          input.disabled = false;
        });
      });
  });

  return {
    onLoad: () => {},
    onUnload: () => {
      msn.unmount();
    },
  };
}
