import fetch from 'unfetch';
import jsonFormatter from './formatters/json';
import initPaymentMethods from './payment-methods';
import initCheckouts from './checkouts';
import initBFF from './bff';
import initAnalytics from './modules/analytics';
import { getSumupProduct } from '../utils/origin';
const initHttpClient = (host, sessionId, product, version, formatter = jsonFormatter) => ({
    host,
    endpoint: (endpoint, params = {}) => {
        params.headers = Object.assign(Object.assign({}, params.headers), { 'X-SumUp-Widget-Session-Id': sessionId });
        if (product) {
            params.headers['Sumup-Product-Origin'] = product.origin;
        }
        return fetch(`${host}/${version ? `${version}/` : ''}${endpoint}`, params).then(formatter);
    },
});
export function initApiClient(host, sessionId, product = getSumupProduct(window.location.href)) {
    const opAnalyticsClient = initHttpClient(host, sessionId, product, 'v0.1', (r) => r.text());
    const v2Client = initHttpClient(host, sessionId, product, 'v0.2', jsonFormatter);
    return {
        checkouts: initCheckouts(v2Client, product),
        paymentMethods: initPaymentMethods(v2Client),
        analytics: initAnalytics(opAnalyticsClient),
    };
}
export function initBffApiClient(host, sessionId, product = getSumupProduct(window.location.href)) {
    const bffClient = initHttpClient(host, sessionId, product, null, jsonFormatter);
    return initBFF(bffClient);
}
