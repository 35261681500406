export default (value, maskList) => {
    if (!maskList || !maskList.length) {
        return value;
    }
    return value.split('').reduce((accumulator, currChar) => {
        if (accumulator.length === maskList.length) {
            return accumulator;
        }
        const charMask = maskList[accumulator.length];
        if (typeof charMask === 'undefined') {
            return `${accumulator}`;
        }
        const isDefinedChar = typeof charMask === 'string';
        if (isDefinedChar) {
            if (charMask === currChar) {
                return `${accumulator}${currChar}`;
            }
            const nextMask = maskList[accumulator.length + 1];
            if (typeof nextMask === 'string') {
                return `${accumulator}${charMask}${nextMask}`;
            }
            const isValidNextChar = nextMask.test(currChar);
            return `${accumulator}${charMask}${isValidNextChar ? currChar : ''}`;
        }
        const isValid = charMask.test(currChar);
        const validChar = isValid ? currChar : '';
        return `${accumulator}${validChar}`;
    }, '');
};
