/* eslint-disable no-bitwise, no-plusplus */

/**
 * Implementation of the algorithm of Luhn
 *
 * Information about the algorithm:
 * https://en.wikipedia.org/wiki/Luhn_algorithm
 * This code is copied from https://jsperf.com/credit-card-validator/39
 * and chosen as most performant and minimalistic
 */
export function luhnCheckFast(cardNumber) {
  let ca;
  let sum = 0;
  let mul = 0;
  let len = cardNumber.length;

  while (len--) {
    // Convert string to number and multiply it by 2 if its position is odd
    ca = parseInt(cardNumber.charAt(len), 10) << mul;
    // Check if the number is above 9 then calculate the sum of its digits
    // example: if 16 then 1 + 6 = 7; if 18 then 1 + 8 = 9
    sum += ca - (ca > 9) * 9; // sum += ca - (-(ca>9))|9
    // 1 <--> 0 toggle.
    mul ^= 1; // mul = 1 - mul;
  }
  return sum % 10 === 0 && sum > 0;
}

export function parseExpiryDate(expiryDate) {
  const [month, year] = expiryDate.split(/\//);
  return { month, year };
}

/**
 * Validate expiration date of a card
 *
 * @param {string} month - month is string from 01 to 12
 * @param {string} year  - year is four-digit year
 * @param {Date} now - (optional) current Date object, required for mocking tests
 */
export function validateExpiryDate(month, year, now = new Date()) {
  if (!year || year.length === 0 || !month || month.length === 0) {
    return false;
  }

  if (year.toString().length === 2) {
    year = now.getFullYear().toString().substr(0, 2) + year;
  }

  if (now.getFullYear() < parseInt(year, 10)) {
    return true;
  }

  if (
    now.getFullYear() === parseInt(year, 10) &&
    now.getMonth() + 1 <= parseInt(month, 10)
  ) {
    return true;
  }
  return false;
}

const ELO_REGEX = [
  // starting with 4:
  '((40117[8-9])|(43(1274|8935))|(451416)|(457393)|(45763[1-2]))',
  // starting with 5:
  '(504175)',
  // range 506699 506778:
  '((506699)|(5067[0-6][0-9])|(50677[0-8]))',
  // range 509000 509999:
  '(509\\d{3})',
  // range 627780:
  '(627780)',
  // range 636297 636368:
  '(636(297|368))',
  // range 650031 650033:
  '(65003[1-3])',
  // range 650035 650051:
  '((63003[5-9])|(65004[0-9])|(65005[0-1]))',
  // range 650405 650439:
  '((65040[5-9])|(6504[1-3][0-9]))',
  // range 650485 650538:
  '((65048[5-9])|(65049[0-9])|(6505[0-2][0-9])|(65053[0-8]))',
  // range 650541 650598:
  '((65054[1-9])|(6505[5-8][0-9])|(65059[0-8]))',
  // range 650700 650718:
  '((65070[0-9])|(65071[0-8]))',
  // range 650720 650727:
  '(65072[0-7])',
  // range 650901 650920:
  '((65090[1-9])|(65091[0-9])|(650920))',
  // range 651652 651679:
  '((65165[2-9])|(6516[6-7][0-9]))',
  // range 655000 655019:
  '(6550[0-1][0-9])',
  // range 655021 655058:
  '((65502[0-9])|(6550[3-4][0-9])|(65505[0-8]))',
].join('|^');

/**
 * Detect the card schema according to the card's number
 *
 * @param {string} cardNumber - card's number
 */
export function getCardType(cardNumber) {
  let cardNum = cardNumber;
  if (cardNum) {
    cardNum = cardNum.replace(/-|\s/g, '');
  }

  const schemes = [
    /*
     * Elo
     * NOTE: this has to be before Visa because of collisions
     */
    {
      brand: 'elo',
      pattern: `^${ELO_REGEX}`,
      min: 16,
      max: 16,
    },
    /*
     * Visa: 4 (Length: 13, 16)
     */
    { brand: 'visa', pattern: '^4', min: 13, max: 16 },
    /*
     * MasterCard: 222100-272099, 51-55 (Length: 16)
     */
    {
      brand: 'mastercard',
      pattern:
        '^(?:2(?:2(?:2[1-9]\\d{2}|[3-9]\\d{3})|[3-6]\\d{4}|7(?:[0-1]\\d{3}|20\\d{2}))|5[1-5]\\d{4})', // eslint-disable-line max-len
      min: 16,
      max: 16,
    },
    /*
     * American Express: 34, 37 (Length: 15)
     */
    { brand: 'amex', pattern: '^3[47]', min: 15, max: 15 },
    /*
     * Diner’s Club: 300-305, 3095, 36, 38-39 (Length: 14)
     */
    {
      brand: 'diners',
      pattern: '^(?:3(?:0(?:[0-5]\\d|95)|[68-9]\\d{2}))',
      min: 14,
      max: 19,
    },
    /*
     * Discover: 6011, 622126-622925, 644-649, 65 (Length: 16)
     */
    {
      brand: 'discover',
      pattern:
        '^6(?:011|22(?:1(?:2[6-9]|[3-9])|[2-8]|9(?:[0-1]|2[0-5]))|4[4-9]|5)',
      min: 16,
      max: 16,
    },
    /*
     * Japan Credit Bureau: 35 (Length: 16)
     */
    { brand: 'jcb', pattern: '^35', min: 16, max: 16 },
    /*
     * Maestro: 50, 56–59, 66-69 (Length: 12, 19)
     */
    {
      brand: 'maestro',
      pattern: '^((5[06789])|(6[6789]))[0-9]{11,18}$',
      min: 12,
      max: 19,
    },
    /*
     * HiperCard: 606282, 637095, 637568, 637599, 637609, 637612, 3841 (Length: 19)
     * NOTE: cards with BIN 3841 are conflicting with Diner's Club card schemes
     */
    {
      brand: 'hipercard',
      pattern:
        '^((606282)|(637095)|(637568)|(637599)|(637609)|(637612)|(3841(0|4|6)0))',
      min: 16,
      max: 19,
    },
    /*
    VR: 627416, 637202, 637200, 637036, 637201, 637200 (Length: 16)
    NOTE: not all BINs are included in the above range, more to be added in the future
    */
    {
      brand: 'vr',
      pattern: '^((627416)|(637202)|(637200)|(637036)|(637201))',
      min: 16,
      max: 16,
    },
  ];

  for (let i = 0; i < schemes.length; i++) {
    const entry = schemes[i];
    if (new RegExp(entry.pattern).test(cardNum)) {
      const validLength =
        cardNum.length >= entry.min && cardNum.length <= entry.max;
      return {
        brand: entry.brand,
        validLength,
        min: entry.min,
        max: entry.max,
      };
    }
  }
  return null;
}

export function validateCity(city) {
  return {
    valid: /^.{1,255}$/.test(city),
    validLength: city.trim().length > 0,
  };
}

export function validateStreet(street) {
  return {
    valid: /^.{1,255}$/.test(street),
    validLength: street.trim().length > 0,
  };
}

export function validatePostalCode(postalCode) {
  return {
    valid: /^.{1,255}$/.test(postalCode),
    validLength: postalCode.trim().length > 0,
  };
}

export function validateStateName(stateName) {
  return {
    valid: /^.{1,255}$/.test(stateName),
    validLength: stateName.trim().length > 0,
  };
}

export function validateZipCode(zipCode) {
  const zipCodePatterns = [
    // USA
    /^\d{5}$/,
    // Canada (https://howtodoinjava.com/regex/canada-postal-code-validation/)
    /^(?!.*[DdFfIiOoQqUu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/,
    // UK
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/, // eslint-disable-line max-len
  ];

  for (let i = 0; i < zipCodePatterns.length; i++) {
    if (zipCodePatterns[i].test(zipCode)) {
      return true;
    }
  }

  return false;
}

export function validateBirthDate(birthDate) {
  const birthDateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/i;

  if (!birthDateRegex.test(birthDate)) {
    return false;
  }

  const [day, month, year] = birthDate.split('/');

  // Date.parse accepts a mm/DD/yyyy pattern
  return !Number.isNaN(Date.parse(`${month}/${day}/${year}`));
}
